<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <!--    <DeleteUser-->
    <!--      :id="deleteUserId"-->
    <!--      ref="delete-user-modal"-->
    <!--      @getAllGroups="getAllGroups()"-->
    <!--    />-->
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            sm="12"
            offset-sm="0"
            offset-md="6"
            offset-lg="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="pageChanged(1)"
              />
              <b-button
                variant="primary"
                @click="pageChanged(1)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allGroups-table"
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allGroups"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
        @row-selected="onRowSelected"
      >
        <!-- Column: id -->
        <template #cell(ID)="data">
          <span class="text-nowrap">
            {{ data.item.id }}
          </span>
        </template>
        <!-- Column: User -->
        <template
          #cell(GROUP_NAME)="data"
        >
          <b-media vertical-align="center">
            <span
              :to="{ name: 'user', params: { id: data.item.id } }"
              class="text-nowrap d-inline-block text-truncate"
              style="max-width: 120px;"
            >
              {{ data.item.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(NO_OF_MEMBERS)="data">
          <span class="text-nowrap">
            {{ data.item.user_group_has_members_count }}
          </span>
        </template>

        <!-- Column: Status -->
        <template
          #cell(TARGET_ADS)="data"
        >
          {{ data.item.target_ads_count || '--' }}
        </template>
        <template
          #cell(APPROVED_TARGET_ADS)="data"
        >
          {{ data.item.approved_target_ads_count || '--' }}
        </template>
        <template
          #cell(INTERNAL_NETWORK_AD)="data"
        >
          {{ data.item.banner_campaigns_count || '--' }}
        </template>
        <template
          #cell(TOTAL_DEVICES)="data"
        >
          {{ data.item.devices_count || '--' }}
        </template>
        <template
          #cell(Actions)="data"
          class="d-flex flex-row justify-content-around"
        >
          <span
            class="no-wrap"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              size="sm"
              class="btn-icon rounded-circle"
              @click="OpenEditBusinessModal(data)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-danger"
              size="sm"
              class="btn-icon rounded-circle m-0"
              @click="Unsubscribe(data)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </template>
      </b-table>
    </b-card>
    <EditBusinessModal
      ref="create-group-modal"
      @refresh-Data="refreshData"
    />
    <CustomPagination
      v-if="allGroups.length"
      :total="paginationObject.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import ActiveType from '@/common/enums/accountStatusEnum'
import CustomPagination from '@/components/common/CustomPagination.vue'
import EditBusinessModal from '@/components/admin/EditBusinessModal.vue'
import { showToast } from '@/common/global/functions'
// import DeleteUser from '@/components/user/DeleteUser.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    // DeleteUser,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BSkeletonTable,
    EditBusinessModal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      ActiveType,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allGroups: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'ID' },
        { key: 'GROUP_NAME', label: 'BUSINESSES NAME' },
        { key: 'NO_OF_MEMBERS' },
        // { key: 'TARGET_ADS' },
        // { key: 'APPROVED_TARGET_ADS' },
        { key: 'INTERNAL_NETWORK_AD' },
        { key: 'TOTAL_DEVICES', label: 'TOTAL SCREENS' },
        { key: 'Actions' },
      ],
      selectMode: 'single',
      selected: [],
    }
  },
  async mounted() {
    await this.getAllGroups(this.paginationObject)
  },
  methods: {
    OpenEditBusinessModal(event) {
      this.$refs['create-group-modal'].show(event.item)
    },
    async Unsubscribe(data) {
      this.$swal.fire({
        title: 'Do you want to cancel your Subscription',
        text: 'All of your data will be lost',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: ' Cancel',
        reverseButtons: true,
        width: '650px',
      }).then(async result => {
        if (result.isConfirmed) {
          this.spinner = true
          try {
            await this.$axios.put('admin/user-has-group/unsubscribe',
              {
                unsubscribe: true,
              },
              {
                headers: {
                  userHasGroupId: data.item.id,
                },
              })
            await this.$swal.fire({
              title: 'UnSubscribed!',
              text: 'Your Subscription has been cancelled.',
              icon: 'success',
            }).then(async result1 => {
              if (result1.isConfirmed) {
                await this.getAllGroups(this.paginationObject)
              }
            })
          } catch ({
            response: {
              data: {
                statusCode,
                message,
              },
            },
          }) {
            showToast('Checkout', message.toString(), 'danger')
          }
        }
      })
    },
    async refreshData() {
      await this.getAllGroups(this.paginationObject)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllGroups(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllGroups(this.paginationObject)
    },
    getAllGroups({ offset, limit }) {
      this.spinner = true
      this.$axios.get(`admin/user-has-group?offset=${offset - 1}&limit=${limit}&search_query=${this.searchQuery}`)
        .then(({ data }) => {
          this.allGroups = data.data.results
          this.paginationObject.total = data.data.total
          this.spinner = false
        }).catch(() => { this.$swal('Businesses not found!') })
    },
    onRowSelected(group) {
      this.selected = group
      this.$router.push(`/groups/${this.selected[0].id}`)
    },
    changeState() {
      this.active = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === ActiveType.ACTIVE) return 'success'
      if (Status === ActiveType.INACTIVE) return 'secondary'
      return 'primary'
    },
    iconFunc(Status) {
      /* eslint-disable global-require */
      if (Status === ActiveType.ACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      if (Status === ActiveType.INACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      return require('@/assets/images/simiicons/Grey.svg')
    },
    deleteModal(e) {
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.circular_image {
  border-radius: 40px;
  background-color: #eeedfd;
}
</style>
